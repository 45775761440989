import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const SliderBox = (props) => {
  const { UValue, setUValue } = props;
  const marks = {
    17: <span className={"Mark"}>0,17</span>,
    18: <span className={"Mark"}>0,18</span>,
    19: <span className={"Mark"}>0,19</span>,
    20: <span className={"Mark"}>0,20</span>,
  };
  return (
    <div className={"Slider-Box"}>
      <p>Laskennallinen U-arvotavoite</p>
      <Slider
        min={17}
        max={20}
        step={1}
        defaultValue={UValue}
        onChange={(value) => setUValue(value)}
        marks={marks}
        railStyle={{ backgroundColor: "#DADADA" }}
        trackStyle={{ backgroundColor: "#FFD500" }}
        dotStyle={{
          borderColor: "#DADADA",
          top: -4,
          height: 12,
          width: 12,
        }}
        activeDotStyle={{ borderColor: "#FFD500" }}
        handleStyle={{
          borderColor: "#FFD500",
          backgroundColor: "#FFD500",
          marginTop: -8,
          height: 20,
          width: 20,
        }}
        dots
      />
    </div>
  );
};

export default SliderBox;
