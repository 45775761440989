const data = [
  {
    wallMaterial: "PU",
    values: [
      {
        UValue: 17,
        requiredThicknesses: {
          RST: 185,
          Alu: 1002,
          Steel: 340,
        },
      },
      {
        UValue: 18,
        requiredThicknesses: {
          RST: 173,
          Alu: 946,
          Steel: 318,
        },
      },
      {
        UValue: 19,
        requiredThicknesses: {
          RST: 164,
          Alu: 895,
          Steel: 301,
        },
      },
      {
        UValue: 20,
        requiredThicknesses: {
          RST: 155,
          Alu: 849,
          Steel: 285,
        },
      },
    ],
  },
  {
    wallMaterial: "Mineraalivilla",
    values: [
      {
        UValue: 17,
        requiredThicknesses: {
          RST: 250,
          Alu: 1068,
          Steel: 402,
        },
      },
      {
        UValue: 18,
        requiredThicknesses: {
          RST: 236,
          Alu: 1005,
          Steel: 379,
        },
      },
      {
        UValue: 19,
        requiredThicknesses: {
          RST: 223,
          Alu: 952,
          Steel: 359,
        },
      },
      {
        UValue: 20,
        requiredThicknesses: {
          RST: 211,
          Alu: 904,
          Steel: 340,
        },
      },
    ],
  },
];

export default data;
