import React, { useState } from "react";
import logo from "./logo.svg";
import Buttons from "./components/Buttons";
import "./App.scss";
import SliderBox from "./components/SliderBox";
import data from "./data";
import Graph from "./components/Graph";

const App = () => {
  const [activeButton, setActiveButton] = useState("PU");
  const [sliderValue, setSliderValue] = useState(18);

  const calculateValues = () => {
    const findWallMaterial = data.find(
      (element) => element.wallMaterial === activeButton
    );

    const findUValue = findWallMaterial.values.find(
      (element) => element.UValue === sliderValue
    );

    return findUValue.requiredThicknesses;
  };

  console.log(calculateValues());

  return (
    <div className={"App"}>
      <div className={"Logo-Container"}>
        <img src={logo} className={"Logo"} alt={"logo"} />
      </div>
      <h1 className={"Main-Header"}>
        Tuulettuvan levyrappauksen U-arvo by64 mukaan
      </h1>
      <Buttons activeButton={activeButton} makeActive={setActiveButton} />
      <SliderBox UValue={sliderValue} setUValue={setSliderValue} />
      <Graph data={calculateValues()} />
      <div className={"Disclaimer"}>
        <p>
          Laskurin toiminta vastaa julkaisujen by64 ja SFS-EN 6946 mukaista
          U-arvolaskentaa.
        </p>
        <p>
          Laskelmat ovat suuntaa-antavia ja tarkoitettu suunnittelijan
          apuvälineiksi. Tarkemmat tiedot ja vaikutukset energiakustannuksiin:
          ota yhteyttä Sto- asiantuntijaan.{" "}
          <a href={"https://sto.fi/"}>https://sto.fi/</a>
        </p>
      </div>
    </div>
  );
};

export default App;
