import React from "react";
import { VictoryChart, VictoryAxis, VictoryBar, VictoryLabel } from "victory";

const Graph = (props) => {
  const { data } = props;

  return (
    <div className="Bar-Chart">
      <h2 className={"Graph-Header"}>
        Tarvittava eristekerroksen paksuus kylmäsiltavaikutus huomioiden
      </h2>
      <VictoryChart
        domainPadding={{ x: 60 }}
        padding={{ left: 130, top: 20, right: 20, bottom: 50 }}
        domain={{ y: [0, 1100] }}
      >
        <VictoryBar
          horizontal
          style={{
            data: {
              fill: ({ datum }) =>
                datum.material === "Sto RST" ? "#FFD500" : "#DADADA",
              width: 50,
            },
            labels: {
              fontFamily: "'Frutiger', sans-serif",
              fontSize: 16,
            },
          }}
          data={[
            { material: "Sto RST", value: data.RST },
            {
              material: "Teräs",
              value: data.Steel,
            },
            {
              material: "Alumiini",
              value: data.Alu,
            },
          ]}
          x="material"
          y="value"
          labels={({ datum }) => `${datum.value} mm`}
          labelComponent={<VictoryLabel x={130} />}
        />
        <VictoryAxis
          style={{
            tickLabels: {
              fontFamily: "'FrutigerBold', sans-serif",
              fontSize: 24,
              fontWeight: "bold",
            },
          }}
          label={"Seinäkiinnikkeen\nmateriaali"}
          axisLabelComponent={
            <VictoryLabel
              angle={0}
              y={30}
              x={120}
              style={{
                fontFamily: "'Frutiger', sans-serif",
                fontSize: 16,
                textAnchor: "end",
              }}
            />
          }
        />
        <VictoryAxis
          dependentAxis
          style={{
            ticks: { stroke: "grey", size: 5 },
            tickLabels: { fontFamily: "'Frutiger', sans-serif", fontSize: 16 },
            grid: { stroke: "grey", strokeDasharray: 5 },
          }}
          tickValues={[300, 600, 900]}
          tickFormat={(t) => `${t} mm`}
        />
      </VictoryChart>
    </div>
  );
};

export default Graph;
