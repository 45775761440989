import React from "react";

const Buttons = (props) => {
  const { activeButton, makeActive } = props;
  return (
    <div className="Buttons">
      <p>Valitse eristemateriaali</p>
      <button
        className={activeButton === "PU" ? "Active" : undefined}
        type={"button"}
        onClick={() => makeActive("PU")}
      >
        <p className={"Label"}>Fenoli</p>
        <p className={"Unit"}>0,021 W/mK</p>
      </button>
      <button
        className={activeButton === "Mineraalivilla" ? "Active" : undefined}
        type={"button"}
        onClick={() => makeActive("Mineraalivilla")}
      >
        <p className={"Label"}>Mineraalivilla</p>
        <p className={"Unit"}>0,033 W/mK</p>
      </button>
    </div>
  );
};

export default Buttons;
